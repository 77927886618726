export const Urls = {
    BaseURL: 'cdn-base-url',

    PATemplate: '087f6e9f-a38c-48f4-a59b-b0ab00f892fd',
    orgId: 'c7bc899a-6e08-4520-a754-b0ab00b80b4f',
    programId: 'e2ebf551-47e4-4b68-9a0f-b0ab011838b4',
    orgIdDirectoryDashboard:'0B57D6D9-F566-4429-8533-B0AB012214E8',
    // Page ids
    page_messages: '1',
    pa_dashboard: '2',
    employer_dashboard: '3',
    pa_profile: '4',
    directProviderReg: '5',
    pa_search: '6',
    page_administration: '8' ,
    page_termsConditions: '12' ,
    page_registrationLandingPage: '14',
    dirRedirectUrl: '20',
    listingSubmitted: '21',
    listingChangeSubmitted: '22'
}